/* General Section Styles */
#features-detail {
  padding: 4rem 2rem;
  background-color: rgb(250, 250, 251); /* Light gray background */
  min-height: 85vh;
  opacity: 0; /* Initially hidden */
  transform: translateY(50px); /* Slide up effect */
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

#features-detail.visible {
  opacity: 1;
  transform: translateY(0); /* Final position when visible */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Header Styles */
.header-features {
  text-align: center;
  margin-bottom: 2rem;
}

.header .title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #000; /* Black title for better contrast */
}

.header .description {
  font-size: 1.25rem;
  color: #333;
  margin-inline: auto;
  width: 60%; /* Darker gray for improved readability */
}

/* Grid Styles */
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 3.2rem;
}

/* Card Styles */
.card {
  padding: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  background-color: #fff; /* White background for cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0; /* Initially hidden */
  transform: translateX(-50px); /* Slide in from the left */
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.card.visible {
  opacity: 1;
  transform: translateX(0); /* Final position when visible */
}

/* Card Title and Description */
.card .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #000; /* Black for card titles */
}

.card .card-description {
  font-size: 1rem;
  color: #555; /* Medium gray for descriptions */
}

/* Mobile-Friendly Adjustments */
@media (max-width: 768px) {
  #features-detail {
    padding: 2rem 1rem;
  }

  .header .description {
    width: 100%; /* Full width for smaller screens */
  }

  .grid {
    grid-template-columns: 1fr; /* Single column on mobile */
  }

  .card {
    transform: translateX(0); /* Remove slide-in for simpler animation on mobile */
  }
}
