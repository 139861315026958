/* Hero Section */
.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fafafb;
    padding: 0 1rem;
    text-align: center;
  }
  
  /* Content Container */
  .hero .content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Title */
  .hero .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 1.2;
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
  }
  
  .hero .title.visible {
    opacity: 1;
  }
  
  /* Description */
  .hero .description {
    font-size: 1.25rem;
    color: #555;
    margin-bottom: 2rem;
    line-height: 1.6;
    opacity: 0;
    transition: opacity 0.8s ease-in-out 0.2s;
  }
  
  .hero .description.visible {
    opacity: 1;
  }
  
  /* Button Container */
  .hero .button-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    opacity: 0;
    transition: opacity 0.8s ease-in-out 0.4s;
  }
  
  .hero .button-container.visible {
    opacity: 1;
  }
  
  /* Call To Action Buttons */
  .hero .cta {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background-color: #000;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    transition: background-color 0.3s ease;
  }
  
  .hero .cta:hover {
    background-color: #333;
  }
  
  .hero .secondary-cta {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .hero .secondary-cta:hover {
    background-color: #000;
    color: #fff;
  }
  
  /* Subtitle */
  .hero .subtitle {
    margin-top: 2rem;
    font-size: 1.5rem;
    color: #333;
    opacity: 0;
    transition: opacity 0.8s ease-in-out 0.6s;
  }
  
  .hero .subtitle.visible {
    opacity: 1;
  }
  
  /* Icons Container */
  .hero .icons-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    opacity: 0;
    transition: opacity 0.8s ease-in-out 0.8s;
  }
  
  .hero .icons-container.visible {
    opacity: 1;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .hero .title {
      font-size: 1.7rem;
    }
  
    .hero .description {
      font-size: 1rem;
    }
  
    .hero .subtitle {
      font-size: 1.25rem;
    }
  
    .hero .cta,
    .hero .secondary-cta {
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
    }
  
    .hero .icons-container {
      gap: 0.75rem;
    }
  }
  