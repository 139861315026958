/* Industry Solutions Section */
.industry-solutions {
    background-color: #fff;
    padding: 3rem 2rem;
    opacity: 0; /* Początkowo ukryte */
    transform: translateY(20px); /* Efekt przesunięcia w górę */
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }
  
  .industry-solutions--visible {
    opacity: 1;
    transform: translateY(0); /* Widoczna pozycja */
  }
  
  /* Header Section */
  .industry-solutions__header {
    text-align: center;
    opacity: 0; /* Początkowo ukryte */
    transform: translateY(20px); /* Efekt przesunięcia w górę */
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }
  
  .industry-solutions__header--visible {
    opacity: 1;
    transform: translateY(0); /* Widoczna pozycja */
  }
  
  .industry-solutions__title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #000;
  }
  
  .industry-solutions__subtitle {
    font-size: 1.25rem;
    color: #555;
    margin-bottom: 4rem;
  }
  
  /* Blocks Container */
  .industry-solutions__container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  
  /* Individual Blocks */
  .industry-solutions__block {
    display: flex;
    gap: 2rem;
    align-items: center;
    flex-wrap: wrap;
    opacity: 0; /* Początkowo ukryte */
    transform: translateX(-50px); /* Efekt wjazdu z lewej */
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }
  
  .industry-solutions__block--visible {
    opacity: 1;
    transform: translateX(0); /* Widoczna pozycja */
  }
  
  .industry-solutions__block--reverse {
    flex-direction: row-reverse; /* Odwrócony układ */
    transform: translateX(50px); /* Efekt wjazdu z prawej */
  }
  
  .industry-solutions__block--reverse.industry-solutions__block--visible {
    transform: translateX(0); /* Widoczna pozycja */
  }
  
  /* Text Block */
  .industry-solutions__text {
    flex: 1;
    max-width: 600px;
  }
  
  .industry-solutions__block-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .industry-solutions__block-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Image */
  .industry-solutions__image {
    flex: 0 0 200px;
    height: 150px; /* Stała wysokość */
    border-radius: 12px;
    object-fit: cover;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    filter: brightness(95%);
    aspect-ratio: 4 / 3; /* Spójny stosunek wymiarów */
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .industry-solutions {
      padding: 2rem 1rem;
    }
  
    .industry-solutions__subtitle {
      margin-bottom: 2rem;
    }
  
    .industry-solutions__block {
      flex-direction: column; /* Układ pionowy na mobile */
      transform: translateX(0); /* Brak efektu wjazdu na mobile */
    }
  
    .industry-solutions__block--reverse {
      flex-direction: column; /* Reset odwróconego układu na mobile */
      transform: translateX(0); /* Brak efektu wjazdu na mobile */
    }
  
    .industry-solutions__image {
      width: 100%;
      height: 150px; /* Stała wysokość */
    }
  
    .industry-solutions__text {
      text-align: center; /* Wyśrodkowany tekst na mobile */
    }
  }
  