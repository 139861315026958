/* Toggle Wrapper */
.pricing__toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    gap: 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: #555;
  }
  
  .pricing__toggle-wrapper .active {
    color: #000;
    font-weight: bold;
  }
  
  /* Toggle Switch */
  .pricing__toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .pricing__toggle input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .pricing__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ddd;
    transition: 0.4s;
    border-radius: 24px;
  }
  
  .pricing__slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  .pricing__toggle input:checked + .pricing__slider {
    background-color: #000;
  }
  
  .pricing__toggle input:checked + .pricing__slider:before {
    transform: translateX(26px);
  }

  .pricing__title{
    font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #000;
  text-align: center;
  }

  .pricing__description {
    text-align: center;
  }
  