/* Benefits Section */
.benefits {
    padding: 4rem 2rem;
    background-color: rgb(250, 250, 251); /* Light gray background */
    min-height: 100vh;
    opacity: 0; /* Hidden initially */
    transform: translateY(50px); /* Slide up effect */
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }
  
  .benefits--visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .benefits__container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    text-align: center;
  }
  
  .benefits__title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #000;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .benefits__title--visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .benefits__content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    width: 100%;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .benefits__content-wrapper--visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .benefits__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
  }
  
  .benefits__list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    opacity: 0;
    transform: translateX(-30px);
  }
  
  .benefits__list-item--visible {
    opacity: 1;
    transform: translateX(0);
  }
  
  .benefits__list-item-icon {
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
  }
  
  .benefits__list-item-text {
    font-size: 1.25rem;
    color: #555;
    line-height: 1.6;
  }
  
  .benefits__image-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    opacity: 0;
    transform: translateX(30px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .benefits__image-wrapper--visible {
    opacity: 1;
    transform: translateX(0);
  }
  
  .benefits__image {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
  }
  
  .benefits__stats-section {
    margin-top: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }
  
  .benefits__stats-section--visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .benefits__stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
  }
  
  .benefits__stat-number {
    font-size: 2.5rem;
    font-weight: bold;
    color: #000;
  }
  
  .benefits__stat-label {
    font-size: 1rem;
    color: #555;
    margin-top: 0.5rem;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .benefits__content-wrapper {
      flex-direction: column;
      gap: 1rem;
    }

    .benefits__stats-section--visible {
        width: 90%;
    }

    .benefits__stat-number{
        font-size: 1.4rem;
    }

    .benefits__stat-label{
        font-size: .8rem;
    }
  
    .benefits__list {
      width: 100%;
    }
  
    .benefits__image-wrapper {
      transform: translateX(0);
    }
  
    .benefits__list-item {
      transform: translateX(0);
    }
  
    .benefits__list-item-icon {
      margin-right: 0.5rem;
    }
  
    .benefits__image-wrapper--visible,
    .benefits__content-wrapper--visible,
    .benefits__list-item--visible {
      transform: translateX(0);
    }
  }
  