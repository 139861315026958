/* Style dla nagłówka */
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease-in-out;
    z-index: 100;
    padding: 1rem 2rem;
  }
  
  .header.scrolled {
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 2rem;
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    transition: font-size 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .nav {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
  
  .link {
    text-decoration: none;
    color: #000;
    font-weight: 500;
    transition: color 0.2s ease;
    cursor: pointer;
  }
  
  .cta {
    padding: 0.5rem 1rem;
    background-color: #000;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    transition: background-color 0.2s ease;
  }
  
  .menu-button {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 101;
  }
  
  .hamburger {
    width: 24px;
    height: 20px;
    position: relative;
    transition: 0.5s ease-in-out;
  }
  
  .hamburger-line {
    position: absolute;
    height: 3px;
    width: 100%;
    background: #000;
    border-radius: 3px;
    transition: transform 0.3s ease-in-out, opacity 0.2s ease;
  }
  
  .hamburger-line:nth-child(1) {
    top: 0;
  }
  
  .hamburger-line:nth-child(2) {
    top: 8px;
  }
  
  .hamburger-line:nth-child(3) {
    top: 16px;
  }
  
  .hamburger.open .hamburger-line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .hamburger.open .hamburger-line:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.open .hamburger-line:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
  .mobile-nav {
    display: none;
  }
  
  .mobile-nav.open {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.98);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 5rem 0rem;
    transition: transform 0.3s ease-in-out;
    z-index: 100;
    border: "1px solid red"
  }
  
  @media (max-width: 768px) {
    .header {
      padding: 0.5rem 0rem;
    }
    .menu-button {
      display: block;
      margin-right: 1rem;
    }
    .nav {
      display: none;
    }

    .header.scrolled {
        background-color: rgba(255, 255, 255, 0.95);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        padding: 0.5rem 0rem;
    }


  }
  