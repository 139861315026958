/* Pricing Card Container */
.pricing-card {
    max-width: 700px;
    margin: 2rem auto;
    width: 30%;
    padding: 2rem;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    border: 1px solid #e0e0e0;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .pricing-card__title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .pricing-card__toggle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0;
  }
  
  .pricing-card__toggle-button {
    padding: 0.5rem 1rem;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    font-weight: 500;
    border-radius: 4px;
    margin: 0 0.25rem;
    transition: background-color 0.2s, color 0.2s;
  }
  
  .pricing-card__toggle-button--active {
    background-color: #000;
    color: #fff;
  }
  
  .pricing-card__price {
    font-size: 2.5rem;
    font-weight: bold;
    color: #000;
    margin: 1rem 0;
  }
  
  .pricing-card__per-user {
    font-size: 1rem;
    color: #555;
    margin-bottom: 2rem;
  }
  
  .pricing-card__benefits-list {
    list-style-type: none;
    padding: 0;
    margin: 2rem 0;
    line-height: 1.8;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  
  .pricing-card__list-item {
    font-size: 1.125rem;
    color: #555;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .pricing-card__special-offer {
    margin-top: 2rem;
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 8px;
    font-size: 1rem;
    color: #444;
    border: 1px solid #ddd;
  }
  
  .pricing-card__contact {
    margin-top: 1.5rem;
    font-size: 1rem;
    color: #0073e6;
    text-decoration: none;
  }
  
  .pricing-card__button {
    margin-top: 1.5rem;
    padding: 0.75rem 2rem;
    background-color: #000;
    color: #fff;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 500;
    display: inline-block;
    transition: background-color 0.3s;
  }
  
  .pricing-card__button:hover {
    background-color: #444;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .pricing-card {
      width: 80%;
      padding: 1.5rem;
      margin-inline: auto;
    }
  
    .pricing-card__title {
      font-size: 1.5rem;
    }
  
    .pricing-card__price {
      font-size: 2rem;
    }
  
    .pricing-card__toggle-container {

      gap: 0.5rem;
    }
  
    .pricing-card__benefits-list {
      text-align: center;
    }
  }
  