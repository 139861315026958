/* Sekcja główna */
#progress-tracker {
    padding: 1rem 2rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-bottom: 4rem;
    min-height: 60vh;
    opacity: 0; /* Ukryty domyślnie */
    transform: translateY(50px); /* Animacja wchodzenia */
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }
  
  #progress-tracker.visible {
    opacity: 1;
    transform: translateY(0); /* Finalna pozycja */
  }
  
  #progress-tracker .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 3rem;
    color: #000;
  }
  
  #progress-tracker .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
    max-width: 1200px;
    width: 100%;
  }
  
  #progress-tracker .steps-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    opacity: 1;
    transform: translateX(0);
    transition: none;
  }
  
  /* Style kroków */
  #progress-tracker .step {
    padding: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  #progress-tracker .step.active {
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  #progress-tracker .step-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #000;
  }
  
  #progress-tracker .step-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-top: 0.5rem;
  }
  
  #progress-tracker .progress-bar {
    height: 5px;
    background-color: #e0e0e0;
    border-radius: 2px;
    margin-top: 0.5rem;
    overflow: hidden;
  }
  
  #progress-tracker .progress-fill {
    height: 100%;
    background-color: #000;
    transition: width 0.2s linear;
  }
  
  /* Kontener wideo */
  #progress-tracker .video-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    height: auto; /* Zachowaj proporcje */
  }
  
  #progress-tracker .video {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
  /* Stylizacja mobilna */
  @media (max-width: 768px) {
    #progress-tracker {
      padding: 1.5rem 1rem;
    }
  
    #progress-tracker .container {
      flex-direction: column;
    }
  
    #progress-tracker .step {
      margin-bottom: 2rem;
    }

    #progress-tracker .video-container {
      margin-top: 1rem;
      border-radius: 0px;
    }

    #progress-tracker .video {
      border-radius: 0px;
    }

  }
  